.homeButton {
/*	background: #ffbc0d;
	color: #292929;*/
/*	background: #9c1f16;*/
	background: #fac564;
	color: #000;
}

.specialButton {
	background: #9c1f16;
	margin: 1vh 10px;
}

.dineInSpecialBoxButton {
	margin: 1vh 10px;
}

.homepageCol {
	margin: 3px auto;
	text-align: center;
}

.homepageColLinks {
	margin: 10px 0;
}

.homepageMainLinks {
	font-family: "Poppins", Arial, sans-serif;
/*	background: #fac564;*/
	color: #fff;
	text-align: center;
	font-weight: 600;
	font-size: 15px;
	
}

.homepageMainLinks:hover {
  text-decoration: none;
  color: #fac564; /* Text color */
}

.homepageButtonHref {
/*	background: #9c1f16;*/
  font-family: "Poppins", Arial, sans-serif;
/*	background: #fac564;*/
	color: #fff;
	display: inline-block;
	padding: 10px 0;
	text-align: center;
	text-decoration: none;
	border-radius: 5px;
/*	font-weight: bold;*/
/*	font-size: 15px;*/
	margin: 10px 0px;
	width: 300px;
	font-weight: 600;
	font-size: 15px;
	border: 2px solid #fff;
}

.homepageButtonHref:hover {
  text-decoration: none;
  color: #fac564; /* Text color */
}

.homeBackground {
	height: '80vh';
  backgroundImage: '../images/homepage_bg.jpg';
  width: '100% !important';
  backgroundSize: '100% auto'; /* Set width to 100% and maintain aspect ratio */
  backgroundRepeat: 'no-repeat';
}

.contactFooter {
	margin: 30px 0;
}

.contactCol {
	margin-left: auto;
 	margin-right: auto;
	text-align: center;
	padding: 10px 5px;
	color: black;
}

.contactHref {
	font-family: "Poppins", Arial, sans-serif;
/*	color: #000;*/
	color: #451400;
  font-weight: 700;
  font-size: 1rem;
  transition: all .12s;
}

.contactHref:hover {
	text-decoration: none;
}


.showCaseCol {
	margin-top: 3vh;
	max-width: 100%;
	height: auto;
} 

.showCaseColText {
	margin-top: 3vh;
	max-width: 100%;
	height: auto;
	padding: 10px;
	border: solid 1px #D3D3D3;
	border-radius: 20px;
}     


.specialsShowCase {
	width: '100% !important';
  margin: '0';
  padding: '5vh 0';
  display: 'flex';
  align-items: 'center';
  justify-content: 'center';
     
}

.speaciasTandC{
	font-size: .8rem;
}