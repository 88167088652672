.drinksContainer {
	margin: 0px 0px 50px 0px;
}

.drinksMenuItem{
	display: flex;
  	align-items: center;
  	justify-content: center;
  	font-size: 18px;
  	text-align: center;
}

.drinksHeader{
	margin: 20px 0px;
}