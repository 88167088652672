.specialsCenterCol {
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	align-items: center;
	justify-content: center;
	width: 100% !important;
	display: flex;
}

.specialsContainer {
	margin: 0px 0px 50px 0px;
}

.specialsHeader{
	margin: 20px 0px;
}

.specialsItem{
	display: flex;
  	align-items: center;
  	justify-content: center;
  	font-size: 18px;
  	text-align: center;
}

