.menuContainer {
	padding: "0 0 20px 0";
}

#menuRow {
  background: rgba(255, 255, 255, 1);
  margin: 10px 0;
  text-transform: capitalize;
  font-size: 1rem;
  top: 10vh;
}

.pricingPolicyRow {
  background: rgba(255, 255, 255, 1);
  margin: 10px 0;
  font-size: 0.7rem;
  top: 10vh;
  color: #9c1f16;
}


#menuRow .nav-link {
	color: #292929;
} 

#menuRow .active {
/*	background: #ffbc0d;
	color: #292929;*/
	background: #9c1f16;
	color: white;
}

.pizzaSizeButton {
	border-radius: 50%;
	margin: 3px 0;
}

.menuItemCardBody {
 padding: 0.55rem;
}